<template>
	<div class="paperCompound">
		<!-- 材料导航 -->
		<material-banner :menuIndex="3"></material-banner>
		
		<!-- 产品特点 -->
		<div class="region-com region-trait k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.ProductFeatures') }}</span>
				<span class="region-title-deputy">{{ $t('m.MultipleAdvantages') }}</span>
			</div>
			<div class="region-desc k_flex_wrap_jcc">
				<div class="region-desc-single k_flex_column_aifs" v-for="(item,index) in articleList" :key="index">
					<span class="region-desc-single-main">{{item.title}}</span>
					<span class="region-desc-single-line"></span>
					<span class="region-desc-single-deputy">{{item.content}}</span>
				</div>
			</div>
		</div>
		
		<!-- 产品展示 -->
		<div class="region-com region-display k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.Applications23') }}</span>
				<span class="region-title-deputy">{{ $t('m.Suitableperformances') }}</span>
			</div>
			<div class="region-cont k_flex_column_aic">
				<div class="region-cont-top k_flex_jcsb_aic">
					<img :src="paperCompoundUrl+$t('m.paperCompounddisplay')" class="region-cont-top-left"/>
					<div class="k_flex_column_jcsb_aic region-cont-top-right">
						<img :src="paperCompoundUrl+'display_02.jpg'"/>
						<img :src="paperCompoundUrl+'display_03.jpg'"/>
					</div>
				</div>
				<img :src="paperCompoundUrl+'display_04.jpg'" class="region-cont-bottom"/>
			</div>
			<div class="region-desc k_flex_wrap_jcc">
				<div class="region-desc-single k_flex">
				
					<div class="region-desc-single-right k_flex_column_aifs_jcsb">
						<span>{{ $t('m.Suitableforindustries') }}</span>
						<span>{{ $t('m.FurnitureindustryPrintingindustry') }}</span>
						<span>Suitable for multiple industries</span>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 产品规格 -->
		<div class="region-com region-size k_flex_column_jcc_aic">
			<div class="region-title k_flex_column_jcc_aic">
				<span class="region-title-main">{{ $t('m.TechnicalSpecification') }}</span>
			</div>
			<div class="region-cont">
				<el-table
					:data="tableData"
					:span-method="objectSpanMethod"
					border
					style="width: 100%; font-size: 16px;">
					<el-table-column
						prop="name"
						label="产品名称"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Product')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="type"
						label="产品型号"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Model')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="standards"
						label="产品规格"
						align="center"
						width="160">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Specification')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="metre"
						label="米数"
						align="center"
						width="140">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Length')}}</div>
						</template>
					</el-table-column>
					<el-table-column
						prop="introduce"
						label="介绍"
						align="center"
						width="599">
						<template slot="header" slot-scope="scope">
							<div>{{$t('m.Introduction')}}</div>
						</template>
						<template slot-scope="scope">
							<div class="region-cont-introduce">
								<div class="region-cont-introduce-single" v-for="(item,index) in scope.row.introduce">
									<span>{{item.title}}</span>
									<span>{{item.content}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<img :src="paperCompoundUrl+$t('m.paperCompoundsize')" class="region-desc">
		</div>
	</div>
</template>

<script>
import materialBanner from '@c/materialBanner/materialBanner'
export default{
	components:{
		materialBanner,
	},
	data() {
		return {
			// articleList:[
			// 	{
			// 		title:'耐塑',
			// 		content:'缠绕膜、透明胶带粘贴7天打印效果依旧清晰'
			// 	},
			// 	{
			// 		title:'耐刮',
			// 		content:'胶带撕拉30次不掉色'
			// 	},
			// 	{
			// 		title:'长效保存',
			// 		content:'按照国家检测中心检测至少保存2年'
			// 	},
			// 	{
			// 		title:'可印刷',
			// 		content:'可用油墨进行印刷'
			// 	},
			// 	{
			// 		title:'防水',
			// 		content:'常温水浸泡24小时，色密度仍在1.0'
			// 	},
			// 	{
			// 		title:'防油',
			// 		content:'油滴浸泡24小时，色密度仍在1.0'
			// 	},
			// 	// {
			// 	// 	title:'防酒精',
			// 	// 	content:'消毒酒精喷在纸上，反复摩擦10次不掉色'
			// 	// }
			// ],
			// tableData: [
			// 	{
			// 		name: '热敏合成纸',
			// 		type: 'SPP3730',
			// 		standards: '1090mm',
			// 		metre: '8000m',
			// 		introduce: [
			// 			{
			// 				title:'耐塑性：',
			// 				content:'使用缠绕膜或透明胶带粘贴缠绕，可保证3个月后打印面字迹可辨识，色密度在0.8以上；'
			// 			},
			// 			{
			// 				title:'耐刮 ：',
			// 				content:'sgs国际检测机构专业检测，3M胶带撕拉30次不掉色不破损；'
			// 			},
			// 			{
			// 				title:'长效保存：',
			// 				content:'按照国家纸张质量监督检验中心检测标准，至少达到2年保存时长；'
			// 			},
			// 			// {
			// 			// 	title:'防酒精：',
			// 			// 	content:'75℃消毒酒精 直接喷洒在纸上，在打印面反复摩擦10次字迹依旧清晰不模糊；'
			// 			// },
			// 			{
			// 				title:'可印刷：',
			// 				content:'可用油墨进行印刷；'
			// 			},
			// 			{
			// 				title:'防水：',
			// 				content:'常温水浸泡24小时，色密度仍在1.0；'
			// 			},
			// 			{
			// 				title:'防油：',
			// 				content:'油滴浸泡24小时，色密度仍在1.0；'
			// 			},
			// 			{
			// 				title:'色密度高：',
			// 				content:'色密度高达1.3以上，显色浓郁清晰；'
			// 			}
			// 		]
			// 	}, 
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1090mm',
			// 		metre: '12000m',
			// 		introduce: []
			// 	},
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1550mm',
			// 		metre: '12000m',
			// 		introduce: []
			// 	},
			// 	{
			// 		name: '',
			// 		type: '',
			// 		standards: '1650mm',
			// 		metre: '12000m',
			// 		introduce: []
			// 	}
			// ]
		}
	},
	computed: {
		paperCompoundUrl() {
		  return 'https://oss-www.kymasf.com/img/material/paperCompound/'
		},
		articleList(){
			return[
				{
					title:this.$t('m.Plasticresistant'),
					content:this.$t('m.Theprintingeffecttape')
				},
				{
					title:this.$t('m.Scratchresistant'),
					content:this.$t('m.Donotlose')
				},
				{
					title:this.$t('m.Longshelflife'),
					content:this.$t('m.Shelflife')
				},
				{
					title:this.$t('m.InkPrintable'),
					content:this.$t('m.Canbeprintedwithinkjetprinter')
				},
				{
					title:this.$t('m.Waterproof'),
					content:this.$t('m.Afterimmersed')
				},
				{
					title:this.$t('m.OilResistant'),
					content:this.$t('m.Afterhoursimmersion')
				},
				// {
				// 	title:'防酒精',
				// 	content:'消毒酒精喷在纸上，反复摩擦10次不掉色'
				// }
			]
		},
		tableData(){
			return [
				{
					name: this.$t('m.SyntheticPaper'),
					type: 'SPP3730',
					standards: '1090mm',
					metre: '8000m',
					introduce: [
						{
							title:this.$t('m.Plasticizerresistanttitle1'),
							content:this.$t('m.Plasticizerresistant1')
						},
						{
							title:this.$t('m.Scratchresistanttitlte1'),
							content:this.$t('m.Scratchresistant1')
						},
						{
							title:this.$t('m.Longshelflifetitle1'),
							content:this.$t('m.Longshelflife1')
						},
						// {
						// 	title:'防酒精：',
						// 	content:'75℃消毒酒精 直接喷洒在纸上，在打印面反复摩擦10次字迹依旧清晰不模糊；'
						// },
						{
							title:this.$t('m.Inkprintabletitle1'),
							content:this.$t('m.Inkprintable1')
						},
						{
							title:this.$t('m.Waterprooftitle1'),
							content:this.$t('m.Waterproof1')
						},
						{
							title:this.$t('m.Oilresistanttitle1'),
							content:this.$t('m.Oilresistant1')
						},
						{
							title:this.$t('m.Highcolourdensitytitle1'),
							content:this.$t('m.Highcolourdensity1')
						}
					]
				}, 
				{
					name: '',
					type: '',
					standards: '1090mm',
					metre: '12000m',
					introduce: []
				},
				{
					name: '',
					type: '',
					standards: '1550mm',
					metre: '12000m',
					introduce: []
				},
				{
					name: '',
					type: '',
					standards: '1650mm',
					metre: '12000m',
					introduce: []
				}
			]
		} 
	},
	mounted() {
		
	},
	methods: {
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4) {
				if (rowIndex % 4 === 0) {
					return {
						rowspan: 4,
						colspan: 1
					};
				} else {
					return {
						rowspan: 0,
						colspan: 0
					};
				}
			}
		}
	}
}
</script>

<style lang="less" scoped>
.paperCompound{
	width: 100%;
	height: 100%;
	background: #fff;
	// 区域公共样式
	.region-com{
		width: 100%;
		.region-title{
			.region-title-main{
				margin-top: 48px;
				height: 48px;
				font-size: 42px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2C3F64;
				line-height: 48px;
				border-bottom: 2px dashed #535353;
				padding-bottom: 15px;
				margin-bottom: 15px
			}
			.region-title-deputy{
				width: 981px;
				height: 44px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2C3F64;
				line-height: 24px;
				text-align: center;
			}
		}
	}
	
	//产品特点
	.region-trait{
		.region-title{
			margin-top: 36px;
			.region-title-main{
				font-size: 42px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #2E3136;
				margin: 0;
				padding: 0;
				border: none;
			}
			.region-title-deputy{
				margin-top: 10px;
				height: 20px;
				font-size: 20px;
				font-family: Adobe Heiti Std;
				font-weight: normal;
				color: #2C3F64
			}
		}
		.region-desc{
			width: 1200px;
			// padding: 46px 144px;
			// height: 362px;
			// background: #257FDB;
			margin: 34px 0 90px 0;
			box-sizing: border-box;
			.region-desc-single{
				width: 380px;
				height: 209px;
				background: #4E71D3;
				border: 1px solid #4268D0;
				// border-radius: 16px;
				margin-left: 30px;
				margin-bottom: 30px;
				padding: 20px;
				box-sizing: border-box;
				color: #FFFFFF;
				// margin: 17px;
				// text-align: center;
				&:nth-child(1){
					margin-left: 0px;
				}
				&:nth-child(4){
					margin-left: 0px;
				}
				.region-desc-single-main{
					font-size: 34px;
					font-family: Adobe Heiti Std;
					font-weight: bold;
				}
				.region-desc-single-line{
					width: 30px;
					height: 3px;
					margin: 20px 0 20px;
					background: #FFFFFF;
				}
				.region-desc-single-deputy{
					font-size: 20px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
				}
			}
		}
	}
	
	//产品展示
	.region-display{
		background: #EFF1F2;
		.region-title{
			
		}
		.region-cont{
			.region-cont-top{
				width: 1200px;
				.region-cont-top-left{
					
				}
				.region-cont-top-right{
					height: 534px;
				}
			}
			.region-cont-bottom{
				margin-top: 25px;
				width: 1200px;
			}
		}
		.region-desc{
			width: 616px;
			height: 244px;
			background: #4E71D3;
			padding: 20px;
			margin-top: 38px;
			box-sizing: border-box;
			margin-bottom: 87px;
			// .region-desc-single-left{
			// 	width: 60px;
			// 	height: 18px;
			// 	background: #FFFFFF;
			// 	margin-top: 8px;
			// 	margin-right: 26px;
			// }
			.region-desc-single-right{
				span{
					&:nth-child(1){
						font-size: 48px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 48px;
					}
					&:nth-child(2){
						font-size: 16px;
						font-family: Adobe Heiti Std;
						font-weight: normal;
						color: #FFFFFF;
						line-height: 18px;
					}
					&:nth-child(3){
						font-size: 16px;
						font-family: Adobe Heiti Std;
						font-weight: normal;
						color: #FFFFFF;
						line-height: 18px;
					}
				}
			}
		}
	}
	
	//产品规格
	.region-size{
		
		.region-cont{
			margin-top: 30px;
			width: 1200px;
			background: #AFBEE9;
			padding: 10px;
			border-radius: 8px;
			box-sizing: border-box;
			font-size: 16px;
			.region-cont-introduce{
				.region-cont-introduce-single{
					font-size: 16px;
					text-align: left;
				}
			}
		}
		.region-desc{
			width: 1200px;
			margin-top: 20px;
			margin-bottom: 56px;
		}
	}
}
</style>